import {
  ITableBodyRow,
  tableComponentNames,
  tableContentItem
} from "@/components/Table/ts/interfaces/TableStructure";

export function mapTableRowsToTableContent(rows: ITableBodyRow[]) {
  return rows.map(({ cells, ...row }) => {
    return {
      ...row,
      cells: cells.map(
        ({
          infoWithImage,
          country,
          checkmark,
          label,
          userActions,
          badge,
          rightAlign,
          json,
          ...cell
        }) => {
          let componentName = tableComponentNames.cellLabel;

          if (infoWithImage) {
            componentName = tableComponentNames.cellInfoWithImage;
          } else if (country) {
            componentName = tableComponentNames.cellCountry;
          } else if (checkmark !== undefined) {
            componentName = tableComponentNames.cellCheckmark;
          } else if (userActions) {
            componentName = tableComponentNames.userActions;
          } else if (badge) {
            componentName = tableComponentNames.cellBadge;
          } else if (json) {
            componentName = tableComponentNames.cellJson;
          }

          return {
            componentName,
            componentContent: (infoWithImage ??
              country ??
              checkmark ??
              label ??
              userActions ??
              badge) as tableContentItem,
            cellProps: cell,
            params: {
              rightAlign: rightAlign ?? false
            }
          };
        }
      )
    };
  });
}
